const getServiceDomainName = () => {
  const hostName = window.location.hostname;
  if (hostName === "localhost" || hostName === "127.0.0.1") {
    return hostName;
  }
  const hostTokens = hostName.split(".");
  if (hostTokens.length >= 2) {
    return hostTokens[hostTokens.length - 2] + "." + hostTokens[hostTokens.length - 1];
  } else {
    return "youma.kr";
  }
}

const BetterAdminConfig = {
  siteName: 'YOUMA',
  logo: '/youma_admin.png',
  homePage: '/',
  authentication: {
    used: true,
    authAPI: () => {
      const serviceDomain = getServiceDomainName();
      const subDomain = 'wop2-api';
      if (process.env.REACT_APP_ENV === "production") {
        return `https://${subDomain}.${serviceDomain}`;
      } else if (process.env.REACT_APP_ENV === "staging") {
        return `https://staging-${subDomain}.${serviceDomain}`;
      } else {
        return "http://localhost:7501"
      }
    },
    errorMessagePermissionDenied: '권한이 없습니다. 관리자에게 문의하세요.'
  },
  // portalServer: () => {
  //   const serviceDomain = getServiceDomainName();
  //   if (process.env.REACT_APP_ENV === "production") {
  //     return "https://www." + serviceDomain;
  //   } else if (process.env.REACT_APP_ENV === "staging") {
  //     if (serviceDomain === "eryuan.co") {
  //       return "https://staging-youma.eryuan.co";
  //     } else {
  //       return `https://staging.${serviceDomain}`;
  //     }
  //   } else {
  //     return "http://staging-youma.eryuan.co"
  //   }
  // },
  adminWebServer: () => {
    const serviceDomain = getServiceDomainName();
    const subDomain = serviceDomain === "eryuan.co" ? "sales" : "admin";
    if (process.env.REACT_APP_ENV === "production") {
      return `https://${subDomain}.${serviceDomain}`;
    } else if (process.env.REACT_APP_ENV === "staging") {
      return `https://staging-${subDomain}.${serviceDomain}`;
    } else {
      return "http://localhost:3006";
    }
  },
  apiServer: () => {
    const serviceDomain = getServiceDomainName();
    const subDomain = 'wop2-api';
    if (process.env.REACT_APP_ENV === "production") {
      return `https://${subDomain}.${serviceDomain}`;
    } else if (process.env.REACT_APP_ENV === "staging") {
      return `https://staging-${subDomain}.${serviceDomain}`;
    } else {
      return "http://localhost:7501"
    }
  },
  errorMessage: {
    serverInternalError: '오류가 발생 했습니다. 관리자에게 문의하세요.',
    badRequestError: '잘못된 요청입니다.',
    pageNotFoundError: '페이지를 찾을 수 없습니다.',
    networkError: '네트워크 연결이 원활하지 않습니다. 다시 한번 시도해 주세요.',
    badAccessPathError: '잘못된 경로 입니다. 메뉴를 통해 접근해 주세요.'
  },
  serverErrorHandlingExcludeUrl: {
    badRequest: [
      "/auth-company-user/refresh",
      "/auth-company-user/login-with-id"
    ]
  }
}

export const adminConfig = BetterAdminConfig;
