import {adminConfig} from "../../config/admin.config";
import Cookies from "universal-cookie";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class CommonApi {
  static getExchangeRate = (options) => {
    let apiPath = `${API_BASE_URL}/exchange-rate?`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath);
  };

  static downloadPrivateFile = (path) => {
    let apiPath = `${API_BASE_URL}/download?path=${path}`;
    return axios.get(apiPath);
  };

}