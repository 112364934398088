import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class AuditReleaseApi {
  static getAuditReleases = (queryParam) => {
    let apiPath = `${API_BASE_URL}/audit-releases`;
    return axios.get(apiPath, { params: queryParam });
  };
  // static createComponent = (param) => {
  //   const apiPath = `${API_BASE_URL}/audit-releases`;
  //   return axios.post(apiPath, param);
  // };
  // static updateComponent = (param) => {
  //   let apiPath = `${API_BASE_URL}/audit-releases/${param.id}`;
  //   return axios.put(apiPath, param);
  // };
  static deleteAuditRelease = (id) => {
    let apiPath = `${API_BASE_URL}/audit-releases/${id}`;
    return axios.delete(apiPath);
  }
  // ========= wxa func ============
  static getQueryQuota = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/queryquota`;
    return axios.get(apiPath, { params: queryParam });
  };
  static bindTester = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/bind-tester`;
    return axios.post(apiPath, param);
  };
  static getQrcode = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/get-qrcode`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getLatestAuditStatus = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/get-latest-auditstatus`;
    return axios.get(apiPath, { params: queryParam });
  };
  static undocodeAudit = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/undocodeaudit`;
    return axios.get(apiPath, { params: queryParam });
  };
  static revertCodeRelease = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/revertcoderelease`;
    return axios.get(apiPath, { params: queryParam });
  };
  static submitAudit = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/submit-audit`;
    return axios.post(apiPath, param);
  };
  static releaseMiniApp = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/release`;
    return axios.post(apiPath, param);
  };
  
}