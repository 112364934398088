import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  message,
  Typography,
  Dropdown,
  Space,
  Menu,
  Modal,
  Form,
  Input
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from './Api'
import Util from '../../util/Util'

const { Text } = Typography

const initialtPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const initialFilter = {
  name: "",
};

const AuditReleaseList = (props) => {
  // console.log('props :>> ', props);
  const [pagination, setPagination] = useState(initialtPagination);
  // const [filter, setFilter] = useState(initialFilter);
  const [queryQuota, setQueryQuota] = useState();
  const [auditReleases, setAuditReleases] = useState([]);
  const [selectedAuditRelease, setSelectedAuditRelease] = useState({});

  const [bindTesterForm] = Form.useForm()
  const [bindTesterVisible, setBindTesterVisible] = useState(false);
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const [qrcodeImg, setQrcodeImg] = useState();

  useEffect(() => {
    getQueryquota()
    loadAuditReleases(1)
  }, []);

  const getQueryquota = async () => {
    let quotaRes = await Api.getQueryQuota()
    // console.log('quotaRes :>> ', quotaRes.data);
    setQueryQuota(quotaRes.data)
  }
  const loadAuditReleases = async (page) => {
    // console.log('pagination :>> ', pagination);
    let params = {
      pageSize: 20,
      page: page,
    };
    let res = await Api.getAuditReleases(params)
    // console.log('loadAuditReleases.res :>> ', res.data);
    setAuditReleases(res.data?.data || [])
    const newPage = {
      current: res.data.page,
      pageSize: res.data.pageSize,
      total: res.data.count,
    }
    setPagination(newPage)
  }


  const refreshAuditReleases = () => {
    loadAuditReleases(1);
  };

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    width: 80,
  },
  {
    title: "模板ID",
    dataIndex: 'templateId',
    key: 'templateId',
    width: 80,
  },
  {
    title: "提交者",
    dataIndex: 'developer',
    key: 'developer',
    width: 100,
  },
  {
    title: "代码版本号",
    dataIndex: 'version',
    key: 'version',
    width: 100,
    ellipsis: true,
  },
  {
    title: "代码描述",
    dataIndex: 'desc',
    key: 'desc',
    width: 100,
    ellipsis: true,
  },
  {
    title: "来源小程序",
    dataIndex: 'fromName',
    key: 'fromName',
    width: 100,
    ellipsis: true,
  },
  {
    title: "已授权项目",
    dataIndex: 'authorizationName',
    key: 'authorizationName',
    width: 100,
    ellipsis: true,
  },
  {
    title: "审核ID",
    dataIndex: 'auditId',
    key: 'auditId',
    width: 100,
    ellipsis: true,
  },
  {
    title: "审核状态",
    dataIndex: 'auditStatus',
    key: 'auditStatus',
    ellipsis: true,
    width: 80,
    render: (text) => {
      return formatAuditStatus(text)
    }
  },
  {
    title: "发布状态",
    dataIndex: 'releaseStatus',
    key: 'releaseStatus',
    ellipsis: true,
    width: 80,
    render: (text) => {
      return text ? '已发布' : ''
    }
  },
  {
    title: "更新日期",
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    width: 180,
    render: (text) => {
      return <>{Util.getLocalTime(text)}</>
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 120,
    align: 'center',
    render: (text, record) => {
      return (
        <Dropdown placement="bottom" overlay={actionMenu} trigger={['click']}>
          <Button type='primary' onClick={(e) => {
            e.preventDefault()
            setSelectedAuditRelease(record)
          }}>
            <Space>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    }
  }];

  const formatAuditStatus = (s) => {
    // AuditStatus==> 0:审核成功, 1:审核被拒绝, 2:审核中, 3:已撤回, 4:审核延后-自定义, '': 未审核
    let str = ''
    switch (s) {
      case 0:
        str = "审核成功"
        break;
      case 1:
        str = "审核被拒绝"
        break;
      case 2:
        str = "审核中"
        break;
      case 3:
        str = "已撤回"
        break;
      case 4:
        str = "审核延后"
        break;
      default:
        break;
    }
    return str
  }
  const handleMenuClick = ({ key }) => {
    console.log('key :>> ', key);
    if (key == '1') { //绑定体验者
      setBindTesterVisible(true)
    } else if (key == '2') {  //体验者二维码
      let param = {
        authorizationAppid: selectedAuditRelease.authorizationAppid,
      }
      Api.getQrcode(param).then(res => {
        // console.log('getQrcodea :>> ', res.data);
        setQrcodeVisible(true)
        setQrcodeImg('data:image/png;base64,' + res.data.qrImage)
      })
    } else if (key == '3') {  //提交审核
      let param = {
        id: selectedAuditRelease.id,
        authorizationAppid: selectedAuditRelease.authorizationAppid,
        templateId: selectedAuditRelease.templateId
      }
      Api.submitAudit(param).then(res => {
        message.success('提交审核成功')
        loadAuditReleases(1)
      })
    } else if (key == '4') {  //最后一次审核状态
      let param = {
        authorizationAppid: selectedAuditRelease.authorizationAppid,
      }
      Api.getLatestAuditStatus(param).then(res => {
        // console.log('getLatestAuditStatus :>> ', res.data);
        message.success(formatAuditStatus(res.data.status))
      })
    } else if (key == '5') {  //审核撤回
      let param = {
        id: selectedAuditRelease.id,
        authorizationAppid: selectedAuditRelease.authorizationAppid,
      }
      Api.undocodeAudit(param).then(res => {
        // console.log('undocodeAudit :>> ', res.data);
        message.success('版本撤回成功')
        loadAuditReleases(1)
      })
    } else if (key == '6') {  //发布
      let param = {
        id: selectedAuditRelease.id,
        authorizationAppid: selectedAuditRelease.authorizationAppid,
      }
      Api.releaseMiniApp(param).then(res => {
        message.success('发布成功')
        loadAuditReleases(1)
      })
    } else if (key == '7') {  //回退到上一个版本
      let param = {
        authorizationAppid: selectedAuditRelease.authorizationAppid,
      }
      Api.revertCodeRelease(param).then(res => {
        // console.log('undocodeAudit :>> ', res.data);
        message.success('回退到上一个版本成功')
      })
    } else if (key == '8') {  //删除
      if (!window.confirm(intl.get("common.confirm.delete", { name: selectedAuditRelease.name }))) {
        return;
      }
      Api.deleteAuditRelease(selectedAuditRelease.id).then(res => {
        message.success('success')
        loadAuditReleases(1)
      })
    }
  };
  const actionMenu = (
    // auditStatus==> 0:审核成功, 1:审核被拒绝, 2:审核中, 3:已撤回
    // releaseStatus 1:已发布 ,'':未发布
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" >
        <Button type="link" >绑定体验者</Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="link">体验者二维码</Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="link" disabled={[0, 2].includes(selectedAuditRelease.auditStatus)}>提交审核</Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button type="link" disabled={!selectedAuditRelease.auditStatus}>最后一次审核状态</Button>
      </Menu.Item>
      <Menu.Item key="5">
        <Button type="link" disabled={selectedAuditRelease.auditStatus != 2}>审核撤回</Button>
      </Menu.Item>
      <Menu.Item key="6">
        <Button type="link" disabled={selectedAuditRelease.auditStatus != 0 || selectedAuditRelease.releaseStatus == 1}>发布</Button>
      </Menu.Item>
      <Menu.Item key="7">
        <Button type="link" disabled={selectedAuditRelease.releaseStatus != 1}>回退到上一个版本</Button>
      </Menu.Item>
      <Menu.Item key="8">
        <Button type="link" disabled={selectedAuditRelease.auditStatus == 2}>删除</Button>
      </Menu.Item>
    </Menu>
  );

  const onSubmit = (e) => {
    // console.log('submit.e :>> ', e);
    // console.log('selectedAuditRelease :>> ', selectedAuditRelease);
    let param = {
      authorizationAppid: selectedAuditRelease.authorizationAppid,
      wechatid: e.wechatid
    }
    // console.log('param :>> ', param);
    Api.bindTester(param).then(res => {
      message.success('success')
      setBindTesterVisible(false)
    })
  }
  return (
    <div>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button type="primary" block size="default" shape="round" onClick={() => loadAuditReleases(1)}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        {/* <div className="extra-op-box">
          <Button type="default" size="middle" onClick={() => showAddModal()}>{intl.get("common.button.add")}</Button>
        </div> */}
      </div>
      <div style={{ marginTop: 0 }}>
        {queryQuota && <div style={{ padding: 20 }}>
          <span>当月分配的审核数: {queryQuota.limit}</span>
          <span style={{ marginLeft: 20 }}>已用审核数: {queryQuota.limit - queryQuota.rest}</span>
          <span style={{ marginLeft: 20 }}>可用审核数: {queryQuota.rest}</span>
        </div >}
        <Table
          size={'small'}
          rowKey={'id'}
          dataSource={auditReleases}
          columns={columns}
          pagination={pagination}
        />
      </div>

      <Modal
        width={800}
        title={"绑定体验者"}
        visible={bindTesterVisible}
        onCancel={() => setBindTesterVisible(false)}
        onOk={() => bindTesterForm.submit()}>
        <Form form={bindTesterForm} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={onSubmit}>
          <Form.Item label="体验者微信Id" name="wechatid" rules={[{ required: true, message: "必填项" }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={800}
        title={"体验版二维码"}
        visible={qrcodeVisible}
        onCancel={() => setQrcodeVisible(false)}
        onOk={() => setQrcodeVisible(false)}>
        <div style={{ textAlign: 'center' }}>
          <img style={{ width: 200, height: 200 }} src={qrcodeImg} />
        </div>
      </Modal>
    </div>
  )
}

export default AuditReleaseList;