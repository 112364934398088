import React from "react";

const messageZhCN = {
  "menu.dashboard": "概况",
  "menu.companyInitSetting": "登录设置",
  "menu.component": "第三方平台",
  "menu.authorization": "已授权项目",
  "menu.codeTemplate": "模板库",
  "menu.auditRelease": '审核与发布',
  "menu.wechatEventLog": '微信事件日志',

  "common.true": "是",
  "common.false": "否",
  "common.createdAt": "录入日期",
  "common.updatedAt": "修改日期",

  "common.button.ok": "确认",
  "common.button.cancel": "取消",
  "common.button.save": "保存",
  "common.button.close": "关闭",
  "common.button.add": "新增",
  "common.button.search": "查询",
  "common.button.show": "查看",
  "common.button.edit": "修改",
  "common.button.list": "列表",
  "common.button.before": "上一步",
  "common.button.next": "下一步",
  "common.button.delete": "删除",
  "common.button.back": "上一步",
  "common.button.download": "下载",
  "common.button.upload": "上传",
  "common.button.fileManager": "图片管理",
  "common.button.sendMessage": "发消息",
  "common.button.multiSetting": "批量设置",
  "common.button.newFolder": "新建文件夾",
  "common.button.qrdownload": "下载二维码",
  "common.button.selectFile": "选择文件",
  "common.button.copy": "复制",
  "common.button.addBulk": "批量上传",
  "common.button.preview": "预览",

  "common.inputField": "请输入{name}",
  "common.selectField": "请选择{name}",
  "common.confirm.delete": "确认删掉{name}",
  "common.confirm.save": "确认保存{name}",
  "common.message.nth": "第{index}个",
  "common.message.saved": "已保存",
  "common.message.deleted": "已删除.",
  "common.message.selectAtLeastOne": "请选择至少一个{name}",
  "common.message.jian": "件",
  "common.message.success": "处理成功",
  "common.message.contactAdmin": "系统问题。请联系系统管理员",

  "common.label.listTotal": "总共{total}条",
  "common.label.total": "总共",
  "common.label.price": "价格",
  "common.label.select": "选择",
  "common.label.call-center": "客服中心",
  "common.label.language.korean": "韩语",
  "common.label.language.chinese": "简体中文",
  "common.label.upload.help": "输入图片链接或者上传图片",
  "common.label.detail": "详细信息",
  "common.label.month": "月",
  "common.label.wechat": "微信",

  "layout.reload": "系统已更新，请刷新浏览器(windows系统: Ctrl+F5, 苹果Mac: Command + Shift + R)",

  "login.login": "登录",
  "login.loginOut": "推出",
  "login.welcome": " ",
  "login.loginId": "账号",
  "login.password": "密码",
  "login.noMember": " ",
  "login.findPasswordTitle": "找回密码",
  "login.findPasswordButton": "忘记密码",
  "login.resetPassword": "设置密码",
  "login.resetPassword.wrongUrl": "올바르지 않은 비밀번호 설정 페이지 입니다. 메일에서 다시 확인하세요.",
  "login.password.changed": "改密码成功",

  "register.register": "注册",
  "register.diffPassword": "两次密码输入不一致",
  "register.shortLoginId": "注册账号需要至少六位",
  "register.shortPassword": "注册密码需要至少八位",
  "register.hasLoginId": " ",
  "register.confirmingPassword": "确认密码",
  "register.agree": "同意",
  "register.must": " ",
  "register.message.agree1": "请同意会员条款",
  "register.message.agree2": "请同意隐私权条款",
  "register.message.alreadyUsedId": "此账号已使用",
  "register.registered": "注册成功",

  
};

export default messageZhCN;