import axios from "axios";
import {adminConfig} from "../config/admin.config";
import {MemberContext} from "./member.context";
import Cookies from "universal-cookie";
import {message} from "antd";
import intl from "react-intl-universal";

const API_URL = adminConfig.authentication.authAPI();

class AuthService {
  async login(id, password) {
    // const params = {
    //   loginId: id,
    //   password: password,
    // }
    // const response = await axios.post(API_URL + "/auth-company-user/login-with-id", params).catch(err => {
    //   console.log("loginErr:", err.response);
    //   if (err.response && err.response.data) {
    //     if (typeof err.response.data === "string") {
    //       message.error(err.response.data);
    //     } else {
    //       message.error(intl.get("common.message.contactAdmin"));
    //     }
    //   } else {
    //     message.error(intl.get("common.message.contactAdmin"));
    //   }
    // });
    // const authResponse = response.data;
    //
    // axios.defaults.headers['Authorization'] = `Bearer ${authResponse.accessToken}`;
    // const memberResponse = await axios.get(API_URL + "/company-users/my");
    // memberResponse.data.lastUsedApplicationId = authResponse.applicationId;
    // MemberContext.memberInformation = memberResponse.data;
    // 여기서는 CHANGE_MEMBER_CONTEXT_EVENT_TOPIC이 발생해도 의미가 없다.
    // 이유는 로그인 후 windown.location으로 메인 페이지로 이동하기 때문이다.
  }

  logout() {
    return axios.post(API_URL + "/auth-company-user/logout").then(() => {
      delete axios.defaults.headers['Authorization'];
    });
  }

  async changeApplication(appID) {
    const response = await axios.get(API_URL + "/auth-company-user/change-app?appId=" + appID);
    const authResponse = response.data;

    axios.defaults.headers['Authorization'] = `Bearer ${authResponse.accessToken}`;
    const memberResponse = await axios.get(API_URL + "/company-users/my");
    memberResponse.data.lastUsedApplicationId = authResponse.applicationId;
    MemberContext.memberInformation = memberResponse.data;
  }

  checkAuth() {
    // TODO 오류를 대비해 Retry 추가해야
    return axios.get(API_URL + "/auth-company-user/check");
  }

  async silentRefresh() {
    delete axios.defaults.headers['Authorization'];
    const authResponse = await axios.post(API_URL + "/auth-company-user/refresh")
    const {accessToken, applicationId} = authResponse.data;
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

    const memberResponse = await axios.get(API_URL + "/company-users/my");
    memberResponse.data.lastUsedApplicationId = applicationId;
    MemberContext.memberInformation = memberResponse.data;
  }
}

const instance = new AuthService()
export {instance as AuthService}
