import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class AuthorizationApi {
  static getAuthorizations = (queryParam) => {
    let apiPath = `${API_BASE_URL}/authorizations`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getAuthorizationById = (id) => {
    let apiPath = `${API_BASE_URL}/authorizations/${id}`;
    return axios.get(apiPath);
  };
  static getVersionInfo = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/getversioninfo`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getMiniAppQrCode = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/get-miniapp-qrcode`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getCategory = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/getcategory`;
    return axios.get(apiPath, { params: queryParam });
  };
  static checkTemplateInfo = (queryParam) => {
    let apiPath = `${API_BASE_URL}/authorizations/check-template-info`;
    return axios.get(apiPath, { params: queryParam });
  };
  static commitMiniApp = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/commit`;
    return axios.post(apiPath, param);
  };
  static submitAudit = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/submit-audit`;
    return axios.post(apiPath, param);
  };
  static releaseMiniApp = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/release`;
    return axios.post(apiPath, param);
  };
  static bindTester = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/bind-tester`;
    return axios.post(apiPath, param);
  };
  static getQrcode = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/get-qrcode`;
    return axios.get(apiPath, { params: queryParam });
  };
  
}