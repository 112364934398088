import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  message,
  // Typography,
  Dropdown,
  Space,
  Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from './Api'
import AuthorizationEditModal from './AuthorizationEditModal';
import Util from '../../util/Util'


const AuthorizationList = (props) => {
  const [uploading, setUploading] = useState(false);
  const [authorizations, setAuthorizations] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedAuthorization, setSelectedAuthorization] = useState({});

  useEffect(() => {
    loadAuthorizations()
  }, []);
  const loadAuthorizations = async () => {
    let res = await Api.getAuthorizations()
    // console.log('loadAuthorizations.res :>> ', res.data);
    setAuthorizations(res?.data?.data || [])
  }
  const showAddModal = () => {
    setSelectedAuthorization({});
    setVisibleEdit(true);
  }
  const refreshAuthorizations = () => {
    loadAuthorizations();
  };
  const refreshAllToken = () => {
    Api.refreshAllToken().then(res => {
      loadAuthorizations()
      message.success('success')
    })
  }
  const authorizationColumns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    width: 80,
  },
  {
    title: "第三方平台Appid",
    dataIndex: 'componentAppid',
    key: 'componentAppid',
    width: 180,
  },
  {
    title: "名称",
    dataIndex: 'name',
    key: 'name',
    width: 140,
  },
  {
    title: "Appid",
    dataIndex: 'appid',
    key: 'appid',
    width: 180,
  },
  {
    title: "备注",
    dataIndex: 'desc',
    key: 'desc',
    width: 100,
    ellipsis: true,
  },
  {
    title: "Token",
    dataIndex: 'token',
    key: 'token',
    width: 200,
    ellipsis: true,
    render: (text) => {
      return <CopyToClipboard text={text}
        onCopy={() => message.success('success')}>
        <a style={{ textDecoration: 'underline' }}>{text}</a>
      </CopyToClipboard>
    }
  },
  // {
  //   title: "RefreshToken",
  //   dataIndex: 'refreshToken',
  //   key: 'refreshToken',
  //   width: 200,
  //   ellipsis: true,
  //   render: (text) => {
  //     return <CopyToClipboard text={text}
  //       onCopy={() => message.success('success')}>
  //       <a style={{ textDecoration: 'underline' }}>{text}</a>
  //     </CopyToClipboard>
  //   }
  // },
  {
    title: "OriginId",
    dataIndex: 'originId',
    key: 'originId',
    ellipsis: true,
    width: 100,
    render: (text) => {
      return <CopyToClipboard text={text}
        onCopy={() => message.success('success')}>
        <a style={{ textDecoration: 'underline' }}>{text}</a>
      </CopyToClipboard>
    }
  },
  {
    title: "更新日期",
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    width: 180,
    render: (text) => {
      return <>{Util.getLocalTime(text)}</>
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 120,
    align: 'center',
    render: (text, record) => {
      return (
        <Dropdown placement="bottom" overlay={actionMenu} trigger={['click']}>
          <Button type='primary' onClick={(e) => {
            e.preventDefault()
            setSelectedAuthorization(record)
          }}>
            <Space>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    }
  }];

  const handleMenuClick = ({ key }) => {
    // console.log('handleMenuClick.selectedAuthorization :>> ', selectedAuthorization);
    // console.log('key :>> ', key);
    if (key == '1') { //编辑
      setVisibleEdit(true)
    } else if (key == '2') { //删除
      if (!window.confirm(intl.get("common.confirm.delete", { name: selectedAuthorization.name }))) {
        return;
      }
      Api.deleteAuthorization(selectedAuthorization.id).then(res => {
        message.success('success')
        loadAuthorizations()
      })
    } else if (key == '3') { //刷新token
      Api.refreshToken(selectedAuthorization.id).then(res => {
        message.success('success')
        loadAuthorizations()
      })
    } else if (key == '4') {

    } else if (key == '5') {
    }
  };
  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {intl.get("common.button.edit")}
      </Menu.Item>
      <Menu.Item key="2">
        {intl.get("common.button.delete")}
      </Menu.Item>
      <Menu.Item key="3">
        刷新Token
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button type="primary" block size="default" shape="round" onClick={loadAuthorizations}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        <div className="extra-op-box">
          <Space>
            <Button type="default" size="middle" onClick={() => showAddModal()}>{intl.get("common.button.add")}</Button>
            <Button type="default" size="middle" onClick={refreshAllToken}>刷新全部TOKEN</Button>
          </Space>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Table
          size={'small'}
          rowKey={'id'}
          dataSource={authorizations}
          columns={authorizationColumns}
          pagination={false}
        />
      </div>

      <AuthorizationEditModal
        visibleEdit={visibleEdit}
        setVisibleEdit={setVisibleEdit}
        selectedAuthorization={selectedAuthorization}
        refreshAuthorizations={refreshAuthorizations}
      />
    </div>
  )
}

export default AuthorizationList;