import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  message,
  Modal,
  Form
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import Api from './Api'
import PropTypes from 'prop-types';

const AuthorizationEditModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (props.selectedAuthorization?.id) {
      form.setFieldsValue({
        id: props.selectedAuthorization.id,
        componentAppid: props.selectedAuthorization.componentAppid,
        name: props.selectedAuthorization.name,
        appid: props.selectedAuthorization.appid,
        desc: props.selectedAuthorization.desc,
        token: props.selectedAuthorization.token,
        refreshToken: props.selectedAuthorization.refreshToken,
        originId: props.selectedAuthorization.originId,
        templateAppid: props.selectedAuthorization.templateAppid,
        auditReleaseId: props.selectedAuthorization.auditReleaseId,
      })
    } else {
      form.resetFields();
    }
  }, [props.selectedAuthorization?.id])

  const onOk = () => {
    form.submit()
  }
  const onCancel = () => {
    props.setVisibleEdit(false)
  }
  const onSubmit = (e) => {
    console.log('submit.e :>> ', e);
    e.auditReleaseId = parseInt(e.auditReleaseId)
    setLoading(true)
    if (props.selectedAuthorization?.id) { // 编辑
      Api.updateAuthorization(e).then(res => {
        setLoading(false)
        message.success('success')
        props.setVisibleEdit(false)
        props.refreshAuthorizations()
      })
    } else { //新增
      Api.createAuthorization(e).then(res => {
        setLoading(false)
        message.success('success')
        props.setVisibleEdit(false)
        props.refreshAuthorizations()
      })
    }
  }
  // common.button.ok
  return (
    <Modal
      width={"800px"}
      title={props.selectedAuthorization?.id ? "编辑" : "新增"}
      forceRender
      visible={props.visibleEdit}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={onSubmit}>
        {props.selectedAuthorization?.id && (
          <Form.Item label="Id" name="id" rules={[{ required: true, message: "必填项" }]}>
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item label="第三方平台Appid" name="componentAppid" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="名称" name="name" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Appid" name="appid" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="备注" name="desc" >
          <Input />
        </Form.Item>
        <Form.Item label="Token" name="token" >
          <Input />
        </Form.Item>
        <Form.Item label="RefreshToken" name="refreshToken" >
          <Input />
        </Form.Item>
        <Form.Item label="OriginId" name="originId" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="模板Appid" name="templateAppid" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="auditReleaseId" name="auditReleaseId">
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
AuthorizationEditModal.propTypes = {
  visibleEdit: PropTypes.bool.isRequired,
  setVisibleEdit: PropTypes.func.isRequired,
  selectedAuthorization: PropTypes.object.isRequired,
  refreshAuthorizations: PropTypes.func.isRequired,
};


export default AuthorizationEditModal