import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
} from 'antd';
// import { DownOutlined } from '@ant-design/icons';
// import intl from "react-intl-universal";
// import Api from './Api'
// import Util from '../../util/Util'
import GuideMiniApp from './components/GuideMiniApp'
import BaseMiniApp from './components/BaseMiniApp'

const Sample = (props) => {
  // 'wx7c9ad42aecbf55fc' 二元
  // wx74c13b858b8c6316 大发啦
  const [authorizationAppid, setAuthorizationAppid] = useState('') 
  useEffect(() => {
    // console.log('Sample.props :>> ', props);
    let url = new URL(window.location.href)
    let appid = url.searchParams.get("appid")
    // console.log('appid :>> ', appid);
    setAuthorizationAppid(appid)
  }, [authorizationAppid])

  return (
    <div className="page-content">
      {/* 没有绑定的小程序时 */}
      {!authorizationAppid && <GuideMiniApp></GuideMiniApp>}
      {/* 已绑定小程序时 */}
      {authorizationAppid && <BaseMiniApp authorizationAppid={authorizationAppid}></BaseMiniApp>}
    </div>
  )
};

export default Sample;