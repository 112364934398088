import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
  Image,
  Form,
  Modal,
  Input
} from 'antd';
// import intl from "react-intl-universal";
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UndoOutlined } from '@ant-design/icons';
import './baseMiniApp.less'
import Api from '../Api'
import { map } from 'lodash';

const BaseMiniApp = (props) => {
  const [loading, setLoading] = useState(false)
  const [miniAppName, setMiniAppName] = useState('')
  const [templateInfo, setTemplateInfo] = useState({})
  const [releaseVersion, setReleaseVersion] = useState('')
  const [newestVersion, setNewestVersion] = useState('')
  const [miniAppQrCode, setMiniAppQrCode] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [miniAppFuncScopeList, setMiniAppFuncScopeList] = useState([])
  const [authVisible, setAuthVisible] = useState(false);

  const [bindTesterForm] = Form.useForm()
  const [bindTesterVisible, setBindTesterVisible] = useState(false);
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const [qrcodeImg, setQrcodeImg] = useState();

  useEffect(() => {
    if (!props.authorizationAppid) return
    // console.log('BaseMiniApp.props :>> ', props);
    getAuthorizations()
    getVersionInfo()
    checkTemplateInfo()
    // getMiniAppQrCode()
    getCategory()
  }, [props.authorizationAppid])
  const getAuthorizations = () => {
    let param = {
      appid: props.authorizationAppid
    }
    Api.getAuthorizations(param).then(res => {
      // console.log('getAuthorizations.res :>> ', res?.data);
      if (res?.data?.data?.length == 1) {
        let obj = res.data.data[0]
        // console.log('obj :>> ', obj);
        Api.getAuthorizationById(obj.id).then(res2 => {
          console.log('detail :>> ', res2.data);
          if (res2.data?.authorizationInfo && res2.data?.authorizerInfo) {
            setMiniAppName(res2.data.authorizerInfo.nick_name)
            setMiniAppQrCode(res2.data.authorizerInfo.qrcode_url)
            setMiniAppFuncScopeList(funcScopeCategoryFormat(res2.data.authorizationInfo.func_info))
          }
        })
      }
    })
  }
  const getVersionInfo = () => {
    let param = {
      authorizationAppid: props.authorizationAppid
    }
    Api.getVersionInfo(param).then(res => {
      // console.log('getVersionInfo.res :>> ', res?.data);
      if (res?.data) {
        if (res.data?.release_info?.release_version) {
          setReleaseVersion(res.data.release_info.release_version + '（使用中）')
        } else {
          setReleaseVersion('未发布')
        }
      }
    })
  }
  const checkTemplateInfo = () => {
    let param = {
      appid: props.authorizationAppid
    }
    Api.checkTemplateInfo(param).then(res => {
      // console.log('checkTemplateInfo.res :>> ', res?.data);
      if (res?.data) {
        setTemplateInfo(res.data)
        setNewestVersion(res.data.templateAuditRelease.version)
      }
    })
  }
  // const getMiniAppQrCode = () => {
  //   let param = {
  //     authorizationAppid: props.authorizationAppid
  //   }
  //   Api.getMiniAppQrCode(param).then(res => {
  //     // console.log('getMiniAppQrCode.res :>> ', res?.data);
  //     if (res?.data) {
  //       setMiniAppQrCode(res?.data.qrImage)
  //     }
  //   })
  // }
  const getCategory = () => {
    let param = {
      authorizationAppid: props.authorizationAppid
    }
    Api.getCategory(param).then(res => {
      // console.log('getCategory.res :>> ', res?.data);
      if (res?.data) {
        setCategoryList(res?.data.categories)
      }
    })
  }
  const formatAuditStatus = (s) => {
    // AuditStatus==> 0:审核成功, 1:审核被拒绝, 2:审核中, 3:已撤回, 4:审核延后-自定义, '': 未审核
    let str = ''
    switch (s) {
      case 0:
        str = "审核成功"
        break;
      case 1:
        str = "审核被拒绝"
        break;
      case 2:
        str = "审核中"
        break;
      case 3:
        str = "已撤回"
        break;
      case 4:
        str = "审核延后"
        break;
      default:
        str = "未提交审核"
        break;
    }
    return str
  }
  const _commitCode = async () => {
    setLoading(true)
    try {
      const auditRelease = await commitMiniApp()
      // console.log('auditRelease :>> ', auditRelease);
      message.success('提交成功')
      checkTemplateInfo()
    } catch (error) {
      message.error('提交失败')
    }
    setLoading(false)
  }
  // const updateVersion = async () => {
  //   setLoading(true)
  //   try {
  //     const auditRelease = await commitMiniApp()
  //     console.log('auditRelease :>> ', auditRelease);
  //     // await submitAudit(auditRelease)
  //     // message.success('提交审核成功')
  //   } catch (error) {
  //     message.error('提交审核失败')
  //   }
  //   setLoading(false)
  // }
  const commitMiniApp = () => {
    const tar = templateInfo.templateAuditRelease

    const param = {
      'templateId': tar.templateId,
      'userVersion': tar.version,
      'userDesc': tar.desc,
      'extJson': tar.extJson,
      'sourceMiniprogramAppid': tar.fromAppid,
      'sourceMiniprogram': tar.fromName,
      'authorizerAppid': props.authorizationAppid,
      'developer': tar.developer
    }
    // console.log('param :>> ', param);
    return Api.commitMiniApp(param).then(res => res.data)
  }
  const _submitAudit = async () => {
    setLoading(true)
    const par = templateInfo.pendingAuditRelease
    let param = {
      id: par.id,
      authorizationAppid: par.authorizationAppid,
      templateId: par.templateId
    }
    try {
      await Api.submitAudit(param)
      message.success('提交审核成功')
      checkTemplateInfo()
    } catch (error) {
      message.error('提交审核失败')
    }
    setLoading(false)
  }

  const _releaseMiniApp = async () => {
    setLoading(true)
    const par = templateInfo.pendingAuditRelease
    let param = {
      id: par.id,
      authorizationAppid: par.authorizationAppid,
    }
    try {
      await Api.releaseMiniApp(param)
      message.success('发布成功')
      checkTemplateInfo()
      getVersionInfo()
    } catch (error) {
      message.error('发布失败')
    }
    setLoading(false)
  }
  const _refreshAll = () => {
    getAuthorizations()
    getVersionInfo()
    checkTemplateInfo()
    getCategory()
  }
  const _submitBindTester = (e) => {
    // console.log('submit.e :>> ', e);
    // console.log('selectedAuditRelease :>> ', selectedAuditRelease);
    let param = {
      authorizationAppid: props.authorizationAppid,
      wechatid: e.wechatid
    }
    // console.log('param :>> ', param);
    Api.bindTester(param).then(res => {
      message.success('success')
      setBindTesterVisible(false)
    })
  }
  const _openTestQrCode = () => {
    let param = {
      authorizationAppid: props.authorizationAppid,
    }
    Api.getQrcode(param).then(res => {
      // console.log('getQrcodea :>> ', res.data);
      setQrcodeVisible(true)
      setQrcodeImg('data:image/png;base64,' + res.data.qrImage)
    })
  }
  const go2pcAuth = () => {
    window.open('https://wop2-api.eryuan.co/wx-event/invite')
  }
  const funcScopeCategoryFormat = (funcInfoList) => {
    // console.log('funcInfoList :>> ', funcInfoList);
    const mapList = [
      {
        id: 17,
        name: '帐号管理权限',
        desc: '帮助小程序获取二维码，进行帐号管理',
        mutex: '否'
      },
      {
        id: 18,
        name: '开发管理与数据分析权限',
        desc: '帮助小程序进行功能开发与数据分析',
        mutex: '是'
      },
      {
        id: 19,
        name: '客服消息管理权限',
        desc: '帮助小程序接收和发送客服消息',
        mutex: '否'
      },
      {
        id: 25,
        name: '开放平台帐号管理权限',
        desc: '帮助小程序绑定开放平台帐号，实现用户身份打通',
        mutex: '是'
      },
      {
        id: 30,
        name: '小程序基本信息设置权限',
        desc: '帮助小程序设置名称、头像、简介、类目等基本信息',
        mutex: '否'
      },
      {
        id: 31,
        name: '小程序认证权限',
        desc: '帮助小程序申请认证',
        mutex: '否'
      },
      {
        id: 52,
        name: '小程序直播管理',
        desc: '于该权限可为有直播需求的小程序实现直播以及直播间管理等功能',
        mutex: '否'
      },
    ]
    let newList = []
    funcInfoList.forEach(item => {
      let findObj = mapList.find(mapItem => mapItem.id === item.funcscope_category.id)
      newList.push(findObj)
    })
    // console.log('newList :>> ', newList);
    return newList
  }
  const _testE = () => {
  }
  return (
    <div className="base-mini-app" >
      <div className='header'>
        <div className='header-l'>
          <div className='name'>{miniAppName}</div>
        </div>
        <div className='header-r'>
          <Button icon={<UndoOutlined />} onClick={_refreshAll}>刷新</Button>
        </div>
      </div>
      <div className='conent'>
        <div className='col-1'>
          <div className='title'>基本信息</div>
          <div className='' style={{ marginTop: 10 }}>
            <span>APPID：</span>
            <span>{props.authorizationAppid}</span>
            <CopyToClipboard text={props.authorizationAppid}
              onCopy={() => message.success('success')}>
              <Button type="link" >复制</Button>
            </CopyToClipboard>
          </div>
          <div className='audit-category' style={{ marginTop: 10 }}>
            <div>提审类目：</div>
            <div>
              {categoryList.map((item, index) => {
                return (<div key={index}>
                  {item.first_name}> {item.second_name}
                </div>)
              })}
            </div>
          </div>
        </div>
        <div className='col-2'>
          <div >
            <span className='title'>线上版本：</span>
            <span>{releaseVersion}</span>
          </div>
          <div className='' style={{ marginTop: 10 }}>
            <span>最新版本：</span>
            <span>{newestVersion}</span>
          </div>
          {templateInfo.pendingAuditRelease && <div style={{ marginTop: 10 }}>
            <span>审核版本：</span>
            <span>{templateInfo.pendingAuditRelease.version}（{formatAuditStatus(templateInfo.pendingAuditRelease.auditStatus)}）</span>
          </div>}
          <div style={{ marginTop: 10 }}>
            {!templateInfo.pendingAuditRelease && templateInfo?.currentAuditRelease?.version != templateInfo?.templateAuditRelease?.version && <Button type='primary' size='' loading={loading} onClick={_commitCode}>升级</Button>}
            {templateInfo.pendingAuditRelease && templateInfo.pendingAuditRelease.auditStatus == null && <Button type='primary' size='' loading={loading} onClick={_submitAudit}>提交审核</Button>}
            {templateInfo.pendingAuditRelease && [1, 3].includes(templateInfo.pendingAuditRelease.auditStatus) && <Button type='primary' size='' loading={loading} onClick={_submitAudit}>重新提交审核</Button>}
            {templateInfo.pendingAuditRelease && [0].includes(templateInfo.pendingAuditRelease.auditStatus) && <Button type='primary' size='' loading={loading} onClick={_releaseMiniApp}>发布</Button>}
          </div>
        </div>
        <div className='col-3'>
          <Image width={160} src={miniAppQrCode}></Image>
        </div>

      </div>
      <div className='footer'>
        <div className='footer-l'>
          {/* <Button type="text" onClick={_testE}>解绑授权</Button> */}
          <Button style={{ marginLeft: 10 }} type="text" onClick={go2pcAuth}>重新授权</Button>
          <Button style={{ marginLeft: 10 }} type="text" onClick={() => setAuthVisible(true)}>已授权权限</Button>
        </div>
        <div className='footer-r'>
          <Button type="link" onClick={() => setBindTesterVisible(true)}>添加预览者</Button>
          <Button style={{ marginLeft: 10 }} type="link" onClick={_openTestQrCode}>获取预览二维码</Button>
        </div>
      </div>

      <Modal
        width={800}
        title={"绑定体验者"}
        visible={bindTesterVisible}
        onCancel={() => setBindTesterVisible(false)}
        onOk={() => bindTesterForm.submit()}>
        <Form form={bindTesterForm} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={_submitBindTester}>
          <Form.Item label="体验者微信Id" name="wechatid" rules={[{ required: true, message: "必填项" }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={800}
        title={"体验版二维码"}
        visible={qrcodeVisible}
        onCancel={() => setQrcodeVisible(false)}
        onOk={() => setQrcodeVisible(false)}>
        <div style={{ textAlign: 'center' }}>
          <img style={{ width: 200, height: 200 }} src={qrcodeImg} />
        </div>
      </Modal>
      <Modal
        width={800}
        title={"授权权限列表"}
        visible={authVisible}
        onCancel={() => setAuthVisible(false)}
        onOk={() => setAuthVisible(false)}>
        <div style={{ textAlign: '' }}>
          {miniAppFuncScopeList.map((item, index) => {
            return (
              <div style={{ marginBottom: 4 }} key={index}>{item.name}-{item.id}</div>
            )
          })}
        </div>
      </Modal>
    </div>
  )
}
BaseMiniApp.propTypes = {
  authorizationAppid: PropTypes.string.isRequired,
};


export default BaseMiniApp