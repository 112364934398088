
import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  message,
  Typography,
  Dropdown,
  Space,
  Menu,
  Tag,
  Modal
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from './Api'
import ComponentApi from '../wx_component/Api'
import AuthorizationApi from '../wx_authorization/Api'

import CodeTemplateUploadModal from './CodeTemplateUploadModal';
import Util from '../../util/Util'

const { Text } = Typography

const CodeTemplateList = (props) => {
  // console.log('props :>> ', props);
  // const [uploading, setUploading] = useState(false);
  const [component, setComponent] = useState({});
  const [draftList, setDraftList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedCodeTemplate, setSelectedCodeTemplate] = useState({});

  useEffect(() => {
    loadList()
    loadAuthorizationList()
  }, []);
  const loadAuthorizationList = async () => {
    let AuthorizationRes = await AuthorizationApi.getAuthorizations()
    // console.log('AuthorizationRes :>> ', AuthorizationRes.data.data);
    setAuthorizationList(AuthorizationRes.data.data || [])
  }
  const loadList = async () => {
    let componentRes = await ComponentApi.getComponents()
    let tempComponent = componentRes.data.data[0]
    // console.log('tempComponent :>> ', tempComponent);
    setComponent(tempComponent)
    let param = {
      accessToken: tempComponent.token
    }
    let draftRes = await Api.getDraftList(param)
    // console.log('draftRes :>> ', draftRes.data);
    draftRes.data.sort((item1, item2) => item2.draft_id - item1.draft_id)
    setDraftList(draftRes.data || [])

    let templatetRes = await Api.getTemplateList(param)
    // console.log('templatetRes :>> ', templatetRes.data);
    templatetRes.data.sort((item1, item2) => item2.template_id - item1.template_id)
    setTemplateList(templatetRes.data || [])
  }
  const refreshCodeTemplates = () => {
    loadList();
  };
  const showCodeTemplateUploadModal = (record) => {
    setSelectedCodeTemplate(record);
    setVisibleEdit(true);
  }

  const addTemplate = (record) => {
    // console.log('record :>> ', record);
    let param = {
      draftId: record.draft_id,
      accessToken: component.token
    }
    // console.log('param :>> ', param);
    Api.addToTemplate(param).then(res => {
      message.success('success')
      loadList()
    })
  }
  const deleteTemplate = (record) => {
    // console.log('record :>> ', record);

    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "确定删除?",
      okText: intl.get("common.button.ok"),
      cancelText: intl.get("common.button.cancel"),
      onOk: () => {
        let param = {
          templateId: record.template_id,
          accessToken: component.token
        }
        // console.log('param :>> ', param);
        Api.deleteTemplate(param).then(res => {
          message.success('success')
          loadList()
        })
      }
    });

  }
  const draftColumns = [{
    title: "草稿模板id",
    dataIndex: 'draft_id',
    key: 'draft_id',
    width: 80,
  },
  {
    title: "来源",
    dataIndex: 'source_miniprogram',
    key: 'source_miniprogram',
    width: 110,
  },
  {
    title: "Appid",
    dataIndex: 'source_miniprogram_appid',
    key: 'source_miniprogram_appid',
    width: 180,
  },
  {
    title: "代码版本",
    dataIndex: 'user_version',
    key: 'user_version',
    width: 110,
    ellipsis: true,
  },
  {
    title: "代码备注",
    dataIndex: 'user_desc',
    key: 'user_desc',
    width: 110,
    ellipsis: true,
  },
  {
    title: "提交者",
    dataIndex: 'developer',
    key: 'developer',
    width: 110,
    ellipsis: true,
  },
  {
    title: "创建日期",
    dataIndex: 'create_time',
    key: 'create_time',
    ellipsis: true,
    width: 160,
    render: (text) => {
      return <>{Util.getLocalTime(new Date(text * 1000))}</>
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 120,
    align: 'center',
    render: (text, record) => {
      return (
        <Button size="middle" onClick={() => addTemplate(record)}>
          添加到模板库
        </Button>
      )
    }
  }];

  const templateColumns = [
    {
      title: "模板id",
      dataIndex: 'template_id',
      key: 'template_id',
      width: 80,
    },
    {
      title: "来源",
      dataIndex: 'source_miniprogram',
      key: 'source_miniprogram',
      width: 110,
    },
    {
      title: "Appid",
      dataIndex: 'source_miniprogram_appid',
      key: 'source_miniprogram_appid',
      width: 180,
    },
    {
      title: "代码版本",
      dataIndex: 'user_version',
      key: 'user_version',
      width: 110,
      ellipsis: true,
    },
    {
      title: "代码备注",
      dataIndex: 'user_desc',
      key: 'user_desc',
      width: 110,
      ellipsis: true,
    },
    {
      title: "提交者",
      dataIndex: 'developer',
      key: 'developer',
      width: 110,
      ellipsis: true,
    },
    {
      title: "创建日期",
      dataIndex: 'create_time',
      key: 'create_time',
      ellipsis: true,
      width: 160,
      render: (text) => {
        return <>{Util.getLocalTime(new Date(text * 1000))}</>
      }
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      width: 120,
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <Button type='primary' size="middle" onClick={() => showCodeTemplateUploadModal(record)}>
              上传
            </Button>
            <Button danger size="middle" onClick={() => deleteTemplate(record)}>
              删除
            </Button>
          </Space>

        )
      }
    }
  ]
  return (
    <div>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button type="primary" block size="default" shape="round" onClick={loadList}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        {/* <div className="extra-op-box">
        </div> */}
      </div>
      <div style={{ marginTop: "10px" }}>
        <Tag>草稿箱</Tag>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table
          size={'small'}
          rowKey={'draft_id'}
          dataSource={draftList}
          columns={draftColumns}
          pagination={false}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Tag>模板库</Tag>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table
          size={'small'}
          rowKey={'template_id'}
          dataSource={templateList}
          columns={templateColumns}
          pagination={false}
        />
      </div>
      <CodeTemplateUploadModal
        visibleEdit={visibleEdit}
        setVisibleEdit={setVisibleEdit}
        authorizationList={authorizationList}
        selectedCodeTemplate={selectedCodeTemplate}
        refreshCodeTemplates={refreshCodeTemplates}
      />
    </div>
  )
}

export default CodeTemplateList;