
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import AuditReleaseList from './AuditReleaseList';
import intl from "react-intl-universal";

const WxAuditRelease = (props) => {
  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
    <div>
      <div className="">
        <Switch>
          <Route path={props.match.path} exact
            render={(props) => (<AuditReleaseList {...props} />)} />
        </Switch>
      </div>
    </div>
  )
}

export default WxAuditRelease;