import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  message,
  Modal,
  Form,
  Select
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import Api from './Api'
import PropTypes from 'prop-types';

const CodeTemplateUploadModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    // console.log('authorizationList :>> ', props.authorizationList);
    if (props.selectedCodeTemplate?.template_id) {
      form.setFieldsValue({
        template_id: props.selectedCodeTemplate.template_id,
        user_version: props.selectedCodeTemplate.user_version,
        user_desc: props.selectedCodeTemplate.user_desc,
      })
    } else {
      form.resetFields();
    }
  }, [props.selectedCodeTemplate?.template_id])

  const onOk = () => {
    form.submit()
  }
  const onCancel = () => {
    props.setVisibleEdit(false)
  }
  const onSubmit = (e) => {
    // console.log('submit.e :>> ', e);
    // console.log('selectedCodeTemplate :>> ', props.selectedCodeTemplate);
    setLoading(true)
    const param = {
      'templateId': e.template_id,
      'userVersion': e.user_version,
      'userDesc': e.user_desc,
      'extJson': '{\"extAppid\":\"\",\"ext\":{},\"window\":{}}',
      'sourceMiniprogramAppid': props.selectedCodeTemplate.source_miniprogram_appid,
      'sourceMiniprogram': props.selectedCodeTemplate.source_miniprogram,
      'authorizerAppid': e.appid,
      'developer': props.selectedCodeTemplate.developer
    }
    // console.log('param :>> ', param);
    Api.commitMiniApp(param).then(res => {
      setLoading(false)
      message.success('success')
      props.setVisibleEdit(false)
      props.refreshCodeTemplates()
    }).catch(err => {
      setLoading(false)
    })
  }
  // common.button.ok
  return (
    <Modal
      width={"800px"}
      title={"上传代码"}
      forceRender
      visible={props.visibleEdit}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={onSubmit}>
        <Form.Item label="模板ID" name="template_id" rules={[{ required: true, message: "必填项" }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label="项目" name="appid" rules={[{ required: true, message: "必填项" }]}>
          <Select >
            {
              props.authorizationList.map((item, index) => (
                <Select.Option value={item.appid} key={item.appid}>{item.name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label="代码版本" name="user_version" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="代码备注" name="user_desc" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
CodeTemplateUploadModal.propTypes = {
  visibleEdit: PropTypes.bool.isRequired,
  setVisibleEdit: PropTypes.func.isRequired,
  authorizationList: PropTypes.array.isRequired,
  selectedCodeTemplate: PropTypes.object.isRequired,
  refreshCodeTemplates: PropTypes.func.isRequired,
};


export default CodeTemplateUploadModal