import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  message,
  Typography,
  Dropdown,
  Space,
  Menu
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from './Api'
import EventLogEditModal from './EventLogEditModal';
import Util from '../../util/Util'

const { Text } = Typography

const EventLogList = (props) => {
  // console.log('props :>> ', props);
  const [loading, setLoading] = useState(false);
  const [eventLogs, setEventLogs] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedEventLog, setSelectedEventLog] = useState({});

  useEffect(() => {
    loadEventLogs()
  }, []);
  const loadEventLogs = async () => {
    setLoading(true)
    let res = await Api.getEventLogs()
    // console.log('loadEventLogs.res :>> ', res.data);
    setEventLogs(res?.data?.data || [])
    setLoading(false)
  }
  
  const refreshEventLogs = () => {
    loadEventLogs();
  };

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    width: 80,
  },
  {
    title: "日期",
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    width: 180,
    render: (text) => {
      return <>{Util.getLocalTime(text)}</>
    }
  },
  // {
  //   title: "消息类型",
  //   dataIndex: 'msgType',
  //   key: 'msgType',
  //   width: 120,
  // },
  {
    title: "事件类型",
    dataIndex: 'eventType',
    key: 'eventType',
    width: 200,
  },
  {
    title: "日志内容",
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 120,
    align: 'center',
    render: (text, record) => {
      return (
        <Dropdown placement="bottom" overlay={actionMenu} trigger={['click']}>
          <Button type='primary' onClick={(e) => {
            e.preventDefault()
            setSelectedEventLog(record)
          }}>
            <Space>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    }
  }];

  const handleMenuClick = ({ key }) => {
    // console.log('handleMenuClick.selectedEventLog :>> ', selectedEventLog);
    // console.log('key :>> ', key);
    if (key == '1') { //编辑
      setVisibleEdit(true)
    } else if (key == '2') { //删除
      if (!window.confirm(intl.get("common.confirm.delete", { name: selectedEventLog.name }))) {
        return;
      }
      Api.deleteEventLog(selectedEventLog.id).then(res => {
        message.success('success')
        loadEventLogs()
      })
    }
  };
  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {intl.get("common.button.edit")}
      </Menu.Item>
      <Menu.Item key="2">
        {intl.get("common.button.delete")}
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button type="primary" block size="default" shape="round" onClick={loadEventLogs}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table
          loading={loading}
          size={'small'}
          rowKey={'id'}
          dataSource={eventLogs}
          columns={columns}
          pagination={false}
        />
      </div>

      <EventLogEditModal
        visibleEdit={visibleEdit}
        setVisibleEdit={setVisibleEdit}
        selectedEventLog={selectedEventLog}
        refreshEventLogs={refreshEventLogs}
      />
    </div>
  )
}

export default EventLogList;