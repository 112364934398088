import React from 'react';

const Dashboard = (props) => {
  return (
    <div className="page-content">
      <div style={{backgroundColor: "#EDF0F4"}}>
        Test
      </div>
    </div>
  )
};

export default Dashboard;