import navigationInfo from "./navigation.json";
import {MemberContext} from "../auth/member.context";
import intl from "react-intl-universal";

export default class NavigationConfig {
  static hasPermissions(memberPermissions, navigationPermissions) {
    // for (let i = 0; i < navigationPermissions.length; i++) {
    //   if (memberPermissions.has(navigationPermissions[i])) {
    //     return true;
    //   }
    // }
    //
    // return false;
    return true;
  }

  static titleLabel(item) {
    return item.titleKey ? intl.get(item.titleKey) : item.title;
  }

  static getItemsByMemberPermission = (selectedApp) => {
    const memberPermissions = new Set();
    if (MemberContext.memberInformation && MemberContext.memberInformation.userType !== "") {
      memberPermissions.add(MemberContext.memberInformation.userType);
    }

    const accessibleGnbItems = [];
    if (navigationInfo.items) {
      // 메뉴 title locale 설정
      const gnbItems = navigationInfo.items.map(gnbItem => {
        gnbItem.items =  gnbItem.items && gnbItem.items.map(snbItem => {
          snbItem.items = snbItem.items && snbItem.items.map(subItem => {
            subItem.title = NavigationConfig.titleLabel(subItem);
            return subItem;
          });
          snbItem.title = NavigationConfig.titleLabel(snbItem);
          return snbItem;
        });
        gnbItem.title = NavigationConfig.titleLabel(gnbItem);
        return gnbItem;
      });
      gnbItems.forEach(gnbItem => {
        // GNB
        if (!gnbItem.accessPermissions || this.hasPermissions(memberPermissions, gnbItem.accessPermissions)) {
          if (!gnbItem.items) {
            return;
          }
          // SNB
          const accessibleSnbItems = [];
          const snbItems = gnbItem.items
          snbItems.forEach(snbItem => {
            // console.log(">>>>snbItem.requireAppId>", snbItem.title, ">>>", snbItem.requireAppId)
            if (snbItem.requireAppId && !selectedApp.id) {
              return;
            }
            if (!snbItem.accessPermissions || this.hasPermissions(memberPermissions, snbItem.accessPermissions)) {
              if (snbItem.items) {
                // Sub
                const accessibleSubItems = [];
                const subItems = snbItem.items
                subItems.forEach(subItem => {
                  if (subItem.requireAppId && !selectedApp.id) {
                    return;
                  }
                  if (!snbItem.accessPermissions || this.hasPermissions(memberPermissions, subItem.accessPermissions)) {
                    accessibleSubItems.push(subItem);
                  }
                });
                snbItem.items = accessibleSubItems;
              }
              accessibleSnbItems.push(snbItem);
            }
          });
          gnbItem.items = accessibleSnbItems;
        }
        accessibleGnbItems.push(gnbItem);
      });
    }
    return accessibleGnbItems;
  }

  static getItemsWithoutMemberPermission = () => {
    const accessibleGnbItems = [];
    if (navigationInfo.items) {
      const gnbItems = navigationInfo.items;
      gnbItems.forEach(gnbItem => {
        // GNB
        if (gnbItem.items) {
          // SNB
          const accessibleSnbItems = [];
          const snbItems = gnbItem.items
          snbItems.forEach(snbItem => {
            if (snbItem.items) {
              // Sub
              const accessibleSubItems = [];
              const subItems = snbItem.items
              subItems.forEach(subItem => {
                accessibleSubItems.push(subItem);
              });
              snbItem.items = accessibleSubItems;
            }

            accessibleSnbItems.push(snbItem);
          });
          gnbItem.items = accessibleSnbItems;
        }
        accessibleGnbItems.push(gnbItem);
      });
    }
    return accessibleGnbItems;
  }

  static getItemsByLink = (pathName, navigationItems) => {
    const result = {
      gnbItem: null,
      snbItem: null,
      subItem: null
    }

    if (navigationItems) {
      for (let i = 0; i < navigationItems.length; i++) {
        const gnbItem = navigationItems[i];

        const currentGnbItem = {
          title: gnbItem.title,
          index: String(i),
          icon: gnbItem.icon,
        }

        if (gnbItem && gnbItem.items) {
          for (let j = 0; j < gnbItem.items.length; j++) {
            const snbItem = gnbItem.items[j];
            const currentSnbItem = JSON.parse(JSON.stringify(snbItem));
            currentSnbItem.index = String(j);
            // {
            //   title: snbItem.title,
            //   index: String(j),
            //   icon: snbItem.icon,
            // }

            if (snbItem.link && snbItem.link === pathName) {
              result.gnbItem = currentGnbItem;
              result.snbItem = currentSnbItem;

              return result;
            }

            if (snbItem && snbItem.items) {
              for (let k = 0; k < snbItem.items.length; k++) {
                const subItem = snbItem.items[k];
                // const currentSubItem = {
                //   title: subItem.title,
                //   index: String(k),
                //   icon: subItem.icon,
                // }
                const currentSubItem = JSON.parse(JSON.stringify(subItem));
                currentSubItem.index = String(k);

                if (subItem.link && subItem.link === pathName) {
                  result.gnbItem = currentGnbItem;
                  result.snbItem = currentSnbItem;
                  result.subItem = currentSubItem;

                  return result;
                }
              }
            }
          }
        }
      }
    }

    return result;
  }
}
