import React, {useEffect, useState} from 'react';
import LoginInfo from "./LoginInfo";
import {Layout, Dropdown, Menu, Alert} from "antd";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";
import {CaretDownOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
import {useHistory} from "react-router-dom";

function Header({props}) {
  const history = useHistory();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  let breadcrumbItems = layoutState.breadcrumbItems;
  if (breadcrumbItems && breadcrumbItems.length >= 1) {
    // breadcrumbItems = breadcrumbItems.slice(1, breadcrumbItems.length);
  }

  let selectedLanguageName = layoutState.language === "zh-CN" ? "简体中文" : "한국어";

  const handleLanguageClick = ({key}) => {
    layoutDispatch({type: 'CLICK_LANGUAGE', key});
  }

  const handleHelCenterClick = ({key}) => {
    if (key === "question") {
      // history.push("/questions");
      // setVisibleQuestionModal(true);
    } else if (key === "guide") {
      history.push("/guide");
    } else if (key === "notice") {
      history.push("/notices");
    }
  };

  const onClickNotice = () => {
    // setVisibleNoticeModal(true);
  };

  return (
    <Layout.Header className="site-layout-header">
      <div className={"gnb-right"}>
        <div className="login-info">
          <LoginInfo/>
        </div>
        <div className="select-language">
          <Dropdown overlay={() =>
            <Menu selectedKeys={layoutState.language} onClick={handleLanguageClick}>
              <Menu.Item key={"zh-CN"}>简体中文</Menu.Item>
              <Menu.Item key={"ko-KR"}>한국어</Menu.Item>
            </Menu>
          }>
            <div>{selectedLanguageName} <CaretDownOutlined /></div>
          </Dropdown>
        </div>
        <div className="select-language">
          <Dropdown overlay={() =>
              <Menu onClick={handleHelCenterClick}>
                <Menu.Item key={"question"}>{intl.get("menu.help-center.question")}</Menu.Item>
                <Menu.Item key={"notice"}>{intl.get("menu.help-center.notice")}</Menu.Item>
                <Menu.Item key={"guide"}>{intl.get("menu.help-center.guide")}</Menu.Item>
              </Menu>
          }>
            <div>{intl.get("menu.help-center")} <CaretDownOutlined /></div>
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  );
}

export default Header;
