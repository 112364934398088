import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.less';
import AppLayout from "./components/layout/AppLayout";
import AuthRoute from "./components/router/AuthRoute";
import LoginIdPwd from "./pages/login/LoginIdPwd";
import {adminConfig} from "./config/admin.config";
import {AuthService} from "./auth/auth.service";
import {Modal, ConfigProvider} from "antd";
import Cookies from "universal-cookie";
import {
  EventBroadcaster,
  SHOW_ERROR_MESSAGE_EVENT_TOPIC,
  CHANGE_LANGUAGE_EVENT_TOPIC
} from "./event/event.broadcaster";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {LayoutProvider} from "./components/layout/AppLayoutContext";
import urlParse from "url-parse";

import zh_CN from 'antd/lib/locale/zh_CN';
import ko_KR from 'antd/lib/locale/ko_KR';

import messageKoKR from './locales/ko-KR';
import messageZhCN from "./locales/zh-CN";

import moment from 'moment';
import intl from 'react-intl-universal';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';

moment.locale('ko');

const localeMessages = {
  "zh-CN": messageZhCN,
  "ko-KR": messageKoKR,
};

const publicUrls = ["/login", "/register", "/find-password", "/reset-password"];
const cookies = new Cookies();

const App = () => {
  const [antLocale, setAntLocale] = useState(ko_KR);
  const [versionCheckTimer, setVersionCheckTimer] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    const pathName = window.location.pathname;
    if (adminConfig.authentication.used &&
        !publicUrls.includes(pathName)) {
      // AuthService.silentRefresh().then().catch(() => {
      //   window.location = "/login";
      // });
      if (!localStorage.logined) {
        window.location = "/login";
      }
    }

    EventBroadcaster.on(SHOW_ERROR_MESSAGE_EVENT_TOPIC, (msg) => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined/>,
        content: msg,
        okText: intl.get("common.button.ok"),
        cancelText: null,
        cancelButtonProps: {style: {display: 'none'}},
      });
    });

    EventBroadcaster.on(CHANGE_LANGUAGE_EVENT_TOPIC, (data) => {
      changeLocale(data, true);
    });
  },[]);

  useEffect(() => {
    let locale = cookies.get('locale');
    if (locale !== "zh-CN" && locale !== "ko-KR") {
      const lang = navigator.language || navigator.userLanguage;
      // en-US, zh-CN, zh, ko
      if (lang.startsWith("zh")) {
        locale = "zh-CN";
      } else {
        locale = "ko-KR";
      }
    }
    changeLocale(locale, false);
    checkVersion();

    // clear timer
    return () => {
      if (versionCheckTimer != null) {
        clearInterval(versionCheckTimer);
      }
    }
  }, []);

  const changeLocale = (locale, reloadPage=false) => {
    const url = urlParse(adminConfig.adminWebServer());
    cookies.set("locale", locale, {path: '/', domain: url.hostname});
    if (locale === "zh-CN") {
      moment.locale('zh-cn');
      setAntLocale(zh_CN);
    } else {
      moment.locale('ko');
      setAntLocale(ko_KR);
    }
    intl.init({
      currentLocale: locale,
      locales: localeMessages,
    });

    if (reloadPage) {
      window.location = "/";
      window.location.reload();
    }
  };

  const checkVersion = () => {
    let version = "";
    const timer = setInterval(() => {
      // SystemSettingApi.getPublicSystemSettings().then(res => {
      //   const versionSc = res.data.find(sc => sc.name === "admin.version");
      //   if (versionSc) {
      //     if (version !== "" && version !== versionSc.value) {
      //       setNeedUpdate(true);
      //     }
      //     version = versionSc.value;
      //   }
      // });
    }, 60 * 1000);
    setVersionCheckTimer(timer);
  };

  const existQueryStringInUrl = (url) => {
    return url.split("?")[1] ? true : false;
  }

  // const getQueryStringInUrl = (url) => {
  //   if (existQueryStringInUrl(url)) {
  //     return url.split("?")[1];
  //   } else {
  //     return null;
  //   }
  // }

  return (<>
    <ConfigProvider locale={antLocale}>
    <LayoutProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={LoginIdPwd} />
            <AuthRoute path={'/'} component={AppLayout} needUpdate={needUpdate}/>
          </Switch>
        </BrowserRouter>
      </LayoutProvider>
    </ConfigProvider>
  </>)
};

export default App;
