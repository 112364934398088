import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class CodeTemplateApi {
  static getDraftList = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/gettemplatedraftlist`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getTemplateList = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wxa/gettemplatelist`;
    return axios.get(apiPath, { params: queryParam });
  };
  
  static addToTemplate = (queryParam) => {
    const apiPath = `${API_BASE_URL}/wxa/addtotemplate`;
    return axios.post(apiPath, {}, { params: queryParam });
  };
  static deleteTemplate = (queryParam) => {
    const apiPath = `${API_BASE_URL}/wxa/deletetemplate`;
    return axios.post(apiPath, {}, { params: queryParam });
  };
  static commitMiniApp = (param) => {
    const apiPath = `${API_BASE_URL}/wxa/commit`;
    return axios.post(apiPath, param);
  };

}