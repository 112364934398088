import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class AuthorizationApi {
  static getAuthorizations = (queryParam) => {
    let apiPath = `${API_BASE_URL}/authorizations`;
    return axios.get(apiPath, { params: queryParam });
  };
  static createAuthorization = (param) => {
    const apiPath = `${API_BASE_URL}/authorizations`;
    return axios.post(apiPath, param);
  };
  static updateAuthorization = (param) => {
    let apiPath = `${API_BASE_URL}/authorizations/${param.id}`;
    return axios.put(apiPath, param);
  };
  static deleteAuthorization = (id) => {
    let apiPath = `${API_BASE_URL}/authorizations/${id}`;
    return axios.delete(apiPath);
  }
  static refreshToken = (id) => {
    let apiPath = `${API_BASE_URL}/authorizations/${id}/refresh-token`;
    return axios.put(apiPath);
  };
  static refreshAllToken = (id) => {
    let apiPath = `${API_BASE_URL}/authorizations/refresh-all-token`;
    return axios.put(apiPath);
  };
}