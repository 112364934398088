import React, {useEffect} from "react";
import {Layout} from "antd";
import "./AppLayout.less";
import {
  CHANGE_MEMBER_CONTEXT_EVENT_TOPIC,
  EventBroadcaster,
  SHOW_LOADING_EVENT_TOPIC
} from "../../event/event.broadcaster";
import Sider from "./Sider";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";
import intl from "react-intl-universal";

const AppLayout = (props) => {
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();

  useEffect(() => {
    const pathname = props.location.pathname;
    layoutDispatch({
      type: 'INIT_NAVIGATION', pathname
    });
  }, [
    props.location.pathname,
    layoutDispatch,
    layoutState.allGnbItems,
  ]);

  useEffect(() => {
    // EventBroadcaster.on(CHANGE_MEMBER_CONTEXT_EVENT_TOPIC, () => {
    //   layoutDispatch({
    //     type: 'REFRESH_ALL_GNB_ITEMS'
    //   });
    // });
    //
    // EventBroadcaster.on(SHOW_LOADING_EVENT_TOPIC, (data) => {
    //   const show = data.show;
    //   layoutDispatch({
    //     type: 'SHOW_LOADING', show
    //   });
    // });
    layoutDispatch({
      type: 'REFRESH_ALL_GNB_ITEMS'
    });
  }, [layoutDispatch]);


  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Layout
      style={{
        minHeight: "100%"
      }}
    >
      <Sider/>
      <Layout className="site-layout">
        {props.needUpdate && (
          <div style={{height: 30, fontSize: 14, width: "100%", background: "#f19d38", display: "flex", alignItems: "center", justifyContent: "center"}}>
            {intl.get("layout.reload")}
            <span style={{marginLeft: 10, height: 24, background: "#f9f9f9", border: "1px solid #dddddd", padding: "2px 10px", fontSize: 12}}>
              <a onClick={reloadPage}>Reload</a>
            </span>
          </div>
        )}

        <Header props={props}/>
        <Content props={props}/>
        <Footer/>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
