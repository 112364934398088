import React from 'react';
import {Layout, Divider} from "antd";
import Util from "../../util/Util";

function Footer () {
  const domain = Util.getServiceDomainName();
  if (domain === "youma.kr") {
    return (
      <Layout.Footer style={{textAlign: "center"}}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div>YOUMA 중국 커머스 플랫폼 ©2022 Created by BETTERCODE</div>
          <Divider type={"vertical"}/>
          <div><a href="https://www.youma.kr/terms_supplier.html" target="_blank">이용약관</a></div>
          <Divider type={"vertical"}/>
          <div><a href="https://www.youma.kr/privacy.html" target="_blank">개인정보처리방침</a></div>
        </div>
      </Layout.Footer>
    )
  } else {
    return (
      <Layout.Footer style={{textAlign: "center"}}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <div>wop version: 1.0.0</div>
        </div>
      </Layout.Footer>
    )
  }
}

export default Footer;
