import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class ComponentApi {
  static getComponents = (queryParam) => {
    let apiPath = `${API_BASE_URL}/components`;
    return axios.get(apiPath, { params: queryParam });
  };
  static createComponent = (param) => {
    const apiPath = `${API_BASE_URL}/components`;
    return axios.post(apiPath, param);
  };
  static updateComponent = (param) => {
    let apiPath = `${API_BASE_URL}/components/${param.id}`;
    return axios.put(apiPath, param);
  };
  static deleteComponent = (id) => {
    let apiPath = `${API_BASE_URL}/components/${id}`;
    return axios.delete(apiPath);
  }
  static refreshToken = (id) => {
    let apiPath = `${API_BASE_URL}/components/${id}/refresh-token`;
    return axios.put(apiPath);
  };
}