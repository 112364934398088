import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  message,
  Modal,
  Form
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import Api from './Api'
import PropTypes from 'prop-types';

const EventLogEditModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const [screenShotImgList, setScreenShotImgList] = useState([])

  useEffect(() => {
    if (props.selectedEventLog?.id) {
      getEventlog(props.selectedEventLog.id)

    } else {
      form.resetFields();
    }
  }, [props.selectedEventLog?.id])

  const getEventlog = (id) => {
    Api.getEventLog(id).then(res => {
      if (res.data) {
        // console.log('getEventlog.res :>> ', res.data);
        let authorization = res.data.authorization
        let appName = authorization.name + '(' + authorization.appid + ')'
        let wxEventLog = res.data.wxEventLog
        form.setFieldsValue({
          id: wxEventLog.id,
          appName: appName,
          msgType: wxEventLog.msgType,
          eventType: wxEventLog.eventType,
          content: wxEventLog.content,
          screenShot: wxEventLog.screenShot,
          originId: wxEventLog.originId,
        })
        setScreenShotImgList(res.data.screenShotImgList || [])
      }
    })
  }
  const onOk = () => {
    form.submit()
  }
  const onCancel = () => {
    props.setVisibleEdit(false)
  }
  const onSubmit = (e) => {
    // console.log('submit.e :>> ', e);
  }
  return (
    <Modal
      width={"800px"}
      title={props.selectedEventLog?.id ? "编辑" : "新增"}
      forceRender
      visible={props.visibleEdit}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={onSubmit}>

        {props.selectedEventLog?.id && (
          <Form.Item label="Id" name="id" >
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item label="应用名" name="appName" >
          <Input disabled />
        </Form.Item>
        <Form.Item label="msgType" name="msgType" >
          <Input disabled />
        </Form.Item>
        <Form.Item label="eventType" name="eventType" >
          <Input disabled />
        </Form.Item>
        <Form.Item label="content" name="content" >
          <Input.TextArea rows={20} disabled />
        </Form.Item>
        <Form.Item label="screenShot" name="screenShot" >
          <Input disabled />
        </Form.Item>
        <Form.Item label="originId" name="originId" >
          <Input disabled />
        </Form.Item>
        <div>
          {screenShotImgList.map((imgUrl, index) => {
            // console.log('imgUrl :>> ', imgUrl);
            return (
              <img style={{ width: 100, height: 100, marginRight: 8 }} src={imgUrl} key={index}></img>
            )
          })}
        </div>
      </Form>
    </Modal>
  )
}
EventLogEditModal.propTypes = {
  visibleEdit: PropTypes.bool.isRequired,
  setVisibleEdit: PropTypes.func.isRequired,
  selectedEventLog: PropTypes.object.isRequired,
  refreshEventLogs: PropTypes.func.isRequired,
};


export default EventLogEditModal