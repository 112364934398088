import React, { useCallback, useState } from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { message } from "antd";
// import { gray600, mainColor2 } from "../../styles/fontColors";
import axios from "axios";
import { adminConfig } from "../../config/admin.config";
// import { AuthService } from "../../auth/auth.service";


const gray600 = "#212121"
const mainColor2 = "#3352F6"

const API_URL = adminConfig.apiServer();

function LoginIdPwd(props) {
  const history = useHistory();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");

  const onClickSignup = useCallback(() => {
    history.push("/signup");
  }, [history]);

  const onClickFindPassword = useCallback(() => {
    history.push("/find-password");
  }, [history]);

  const onChangeLoginId = useCallback((e) => {
    setLoginId(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const onChangePasswordKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const onClickLogin = () => {
    login();
  };

  const login = () => {
    if (!loginId) {
      message.error("请输入账号");
      return;
    }

    if (!password) {
      message.error("请输入密码");
      return;
    }
    if (loginId != "youma") {
      message.error("该账号不存在");
      return;
    }
    if (password !="youma123+") {
      message.error("密码错误");
      return;
    }
    localStorage.setItem("logined", true)
    history.push("/");

    // AuthService.loginWithId(loginId, password).then(() => {
    //   history.push("/");
    // }).catch((err) => {
    //   console.log(">>login.error:", err.response);
    //   const isBusinessError = err.response && err.response.data && err.response.data.errorCode;
    //   if (isBusinessError) {
    //     message.error(intl.get("common.error." + err.response.data.errorCode));
    //     return;
    //   }
    //   message.error(intl.get("common.message.contactAdmin"));
    // });

    // const params = {
    //   loginId: loginId,
    //   password: password,
    // };
    // const authResponse = axios.post(
    //   API_URL + "/auth/login-with-id-web",
    //   params
    // ).then(res => {
    //   const token = res.data;
    //   document.location.href = API_URL + "/auth/login-success?token=" + token;
    // }).catch((err) => {
    //   console.log(">>login.error:", err.response);
    //   const isBusinessError = err.response && err.response.data && err.response.data.errorCode;
    //   if (isBusinessError) {
    //     message.error(intl.get("common.error." + err.response.data.errorCode));
    //     return;
    //   }
    //   message.error(intl.get("common.message.contactAdmin"));
    // });

  };

  return (
    <Container>
      <span>登录</span>
      <LoginInput
        value={loginId || ""}
        onChange={onChangeLoginId}
        placeholder="请输入账号"
        type="text"
      />
      <LoginInput
        value={password || ""}
        onChange={onChangePassword}
        onKeyPress={onChangePasswordKeyPress}
        placeholder="请输入密码"
        type="password"
      />
      <LoginConfirmBtn onClick={onClickLogin}>{intl.get("common.button.ok")}</LoginConfirmBtn>
      {/* <div style={{ display: "flex", gap: 10 }}>
        <GoSignup onClick={onClickSignup}>{intl.get("header.signup")}</GoSignup>
        <GoFindPassword onClick={onClickFindPassword}>{intl.get("header.findPassword")}</GoFindPassword>
      </div> */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  span {
    color: ${gray600};
    font-size: 24px;
    font-weight: 700;
    margin: 18px auto 34px;
  }
`;

const LoginInput = styled.input`
  width: 368px;
  height: 45px;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  margin-bottom: 18px;
  font-size: 16px;
  color: ${gray600};
  box-sizing: border-box;
  padding: 10px 16px;
`;

const LoginConfirmBtn = styled.button`
  width: 368px;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  background-color: ${mainColor2};
  color: white;
  border: none;
  cursor: pointer;
`;

const GoSignup = styled.div`
  cursor: pointer;
  color: #9e9e9e;
  margin-top: 16px;
  font-size: 14px;
`;

const GoFindPassword = styled.div`
  cursor: pointer;
  color: #3352f6;
  margin-top: 16px;
  font-size: 14px;
`;

export default LoginIdPwd;
