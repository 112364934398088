import React, { useEffect, useState } from 'react';

import {
  Button,
  Input,
  message,
  Modal,
  Form
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import Api from './Api'
import PropTypes from 'prop-types';

const ComponentEditModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (props.selectedComponent?.id) {
      form.setFieldsValue({
        id: props.selectedComponent.id,
        name: props.selectedComponent.name,
        appid: props.selectedComponent.appid,
        secret: props.selectedComponent.secret,
        ticket: props.selectedComponent.ticket,
        token: props.selectedComponent.token,
        callbackUrl: props.selectedComponent.callbackUrl,
      })
    } else {
      form.resetFields();
    }
  }, [props.selectedComponent?.id])

  const onOk = () => {
    form.submit()
  }
  const onCancel = () => {
    props.setVisibleEdit(false)
  }
  const onSubmit = (e) => {
    console.log('submit.e :>> ', e);
    setLoading(true)
    if (props.selectedComponent?.id) { // 编辑
      Api.updateComponent(e).then(res => {
        setLoading(false)
        message.success('success')
        props.setVisibleEdit(false)
        props.refreshComponents()
      })
    } else { //新增
      Api.createComponent(e).then(res => {
        setLoading(false)
        message.success('success')
        props.setVisibleEdit(false)
        props.refreshComponents()
      })
    }
  }
  // common.button.ok
  return (
    <Modal
      width={"800px"}
      title={props.selectedComponent?.id ? "编辑" : "新增"}
      forceRender
      visible={props.visibleEdit}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} onFinish={onSubmit}>
        {props.selectedComponent?.id && (
          <Form.Item label="Id" name="id" rules={[{ required: true, message: "必填项" }]}>
            <Input disabled />
          </Form.Item>
        )}
        <Form.Item label="名称" name="name" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Appid" name="appid" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Secret" name="secret" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Ticket" name="ticket" >
          <Input />
        </Form.Item>
        <Form.Item label="Token" name="token" >
          <Input />
        </Form.Item>
        <Form.Item label="CallbackUrl" name="callbackUrl" rules={[{ required: true, message: "必填项" }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
ComponentEditModal.propTypes = {
  visibleEdit: PropTypes.bool.isRequired,
  setVisibleEdit: PropTypes.func.isRequired,
  selectedComponent: PropTypes.object.isRequired,
  refreshComponents: PropTypes.func.isRequired,
};


export default ComponentEditModal