import React, { useEffect, useState } from 'react';
import {
  Button,
  Image
} from 'antd';
// import intl from "react-intl-universal";
import PropTypes from 'prop-types';
import './guideMiniApp.less'
import Icon001 from "../../../asset/images/set-mini-app-001.png";
const GuideMiniApp = (props) => {

  useEffect(() => {
  }, [])
  const go2pcAuth = () => {
    window.open('https://wop2-api.eryuan.co/wx-event/invite')
  }
  return (
    <div className="mini-app-box" >
      <div className='sub-box'>
        <div className='title'>将微信小程序绑定店铺，提升交易量</div>
        <div className='item-box'>
          <div className='item-l'>
            <Image width={60} src={Icon001}></Image>
            <Button style={{ marginTop: 20, borderRadius: 6 }} size="large" type='primary' onClick={go2pcAuth}>已有小程序，立即绑定</Button>
            <div style={{ color: '#9797A1', fontSize: 12, marginTop: 20 }}>
              <div style={{ textAlign: 'center' }}>为保证所有功能的正常使用，</div>
              <div style={{ textAlign: 'center' }}>授权时请把所有权限统一授权给忧码云平台</div>
            </div>

          </div>
          <div className='item-r'></div>
        </div>
        {/* <div className='desc-box'>
            <div className='b-box'>
              1
            </div>
          </div> */}
      </div>
    </div>
  )
}
GuideMiniApp.propTypes = {

};


export default GuideMiniApp