import React, {useEffect} from "react";
import {Avatar, Dropdown, Menu} from "antd";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {adminConfig} from "../../config/admin.config";
import {MemberContext} from "../../auth/member.context";
import {AuthService} from "../../auth/auth.service";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";
import {EventBroadcaster, CHANGE_APPLICATION_EVENT_TOPIC} from "../../event/event.broadcaster";
import intl from 'react-intl-universal';
import { CaretDownOutlined } from '@ant-design/icons';

function LoginInfo({props}) {
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const history = useHistory();

  useEffect(() => {
    EventBroadcaster.on(CHANGE_APPLICATION_EVENT_TOPIC, (data) => {
      history.push("/");
    });
  }, []);

  const logout = () => {
    // remove all tab
    layoutDispatch({type:'REMOVE_ALL_TAB'});

    // AuthService.logout().then(() => {
    //   history.replace("/login");
    // }).catch(() => {
    //   history.replace("/login");
    // });
  };

  const hasMultipleApps = () => {
    return MemberContext.memberInformation.applicationUserRoles && MemberContext.memberInformation.applicationUserRoles.length > 1;
  }

  const handleApplicationClick = (key) => {
    AuthService.changeApplication(key).then(res => {
      layoutDispatch({type:'CHANGE_APPLICATION', key})
    });
  }

  const selectedMenuKey = layoutState.application.id ? "md-" + layoutState.application.id : "";

  return (
      <>
        <Dropdown overlay={() =>
            <Menu selectedKeys={[selectedMenuKey]}>
              {hasMultipleApps() && (
                  MemberContext.memberInformation.applicationUserRoles.map((ar, index) => {
                    return (<Menu.Item key={`md-${ar.id}`}
                                       onClick={() => handleApplicationClick(ar.application.id)}>{ar.application.name}</Menu.Item>)
                  })
              )}
              {hasMultipleApps() && <Menu.Divider/>}
              <Menu.Item key="1" onClick={logout}><LogoutOutlined/>&nbsp;{intl.get("login.loginOut")}</Menu.Item>
            </Menu>}>
        <span style={{cursor: "pointer"}}>
          <span style={{marginRight: "2px"}}>{MemberContext.memberInformation && MemberContext.memberInformation.name}</span>
          <CaretDownOutlined />
        </span>
        </Dropdown>
      </>
  )
};

export default LoginInfo;

