import React from "react";
import {Button, Descriptions, Select} from "antd";

const messageKoKR = {
  "menu.dashboard": "Dashboard",
  "menu.companyInitSetting": "로그인 설정",
  "menu.component": "第三方平台",
  "menu.authorization": "已授权项目",
  "menu.codeTemplate": "模板库",
  "menu.auditRelease": '审核与发布',
  "menu.wechatEventLog": '微信事件日志',
  
  "common.true": "예",
  "common.false": "아니오",
  "common.createdAt": "등록일",
  "common.updatedAt": "수정일",

  "common.button.ok": "확인",
  "common.button.cancel": "취소",
  "common.button.save": "저장",
  "common.button.close": "닫기",
  "common.button.add": "추가",
  "common.button.search": "조회",
  "common.button.show": "상세",
  "common.button.edit": "수정",
  "common.button.list": "목록",
  "common.button.before": "이전",
  "common.button.next": "다음",
  "common.button.delete": "삭제",
  "common.button.back": "뒤로",
  "common.button.download": "다운로드",
  "common.button.upload": "업로드",
  "common.button.fileManager": "이미지관리",
  "common.button.sendMessage": "메시지 보내기",
  "common.button.multiSetting": "일괄설정",
  "common.button.newFolder": "새폴더",
  "common.button.qrdownload": "QRCode 다운로드",
  "common.button.selectFile": "파일선택",
  "common.button.copy": "복사",
  "common.button.addBulk": "일괄등록",
  "common.button.preview": "미리보기",

  "common.inputField": "{name}을(를) 입력하세요.",
  "common.selectField": "{name}을(를) 선택하세요.",
  "common.confirm.delete": "{name}을(를) 삭제 하시겠습니까?",
  "common.confirm.save": "{name}을(를) 저장 하시겠습니까?",
  "common.message.nth": "{index}번째",
  "common.message.deleted": "삭제되었습니다.",
  "common.message.saved": "저장되었습니다.",
  "common.message.selectAtLeastOne": "하나 이상의 {name}을 선택하세요.",
  "common.message.jian": "개",
  "common.message.success": "정상 처리되었습니다.",
  "common.message.contactAdmin": "문제가 발생했습니다. 시스템 관리자에게 문의하세요.",

  "common.label.listTotal": "전체 {total}건",
  "common.label.total": "전체",
  "common.label.price": "가격",
  "common.label.password": "비밀번호",
  "common.label.select": "판매여부",
  "common.label.call-center": "고객센터",
  "common.label.language.korean": "한국어",
  "common.label.language.chinese": "중국어",
  "common.label.detail": "상세정보",
  "common.label.upload.help": "이미지 링크 직접 입력 또는 오른쪽 버튼 눌러서 업로드 및 선택",
  "common.label.month": "월",
  "common.label.wechat": "위챗",

  "common.paymentCompany.wechatpay": "위챗직접지불(중국법인)",
  "common.paymentCompany.eximbay": "엑심베이",
  "common.paymentCompany.smatro": "스마트로",
  "common.paymentCompany.nicepay": "나이스페이",

  "layout.reload": "시스템이 업데이트 되었습니다. 웹 페이지를 리로드하세요(윈도우: Ctrl+F5, 맥: Command + Shift + R)",
  "login.login": "로그인",
  "login.loginOut": "로그아웃",
  "login.welcome": "요우마 서비스에 오신것을 환영합니다.",
  "login.loginId": "아이디",
  "login.password": "비밀번호",
  "login.noMember": "회원이 아니신가요?",
  "login.findPasswordTitle": "비밀번호찾기",
  "login.findPasswordButton": "비밀번호찾기",
  "login.resetPassword": "비밀번호 변경",
  "login.resetPassword.wrongUrl": "올바르지 않은 비밀번호 설정 페이지 입니다. 메일에서 다시 확인하세요.",
  "login.password.changed": "비밀번호가 변경되었습니다.",

  "register.register": "회원가입하기",
  "register.diffPassword": "입력한 비밀번호와 다릅니다. 동일하게 입력해주세요.",
  "register.shortLoginId": "ID는 6자리 이상 입력하세요.",
  "register.shortPassword": "비밀번호는 8자리 이상 입력하세요.",
  "register.hasLoginId": "이미 계정이 있으신가요?",
  "register.confirmingPassword": "비밀번호확인",
  "register.agree": "동의",
  "register.must": "(필수)",
  "register.message.agree1": "이용 약관에 동의하세요",
  "register.message.agree2": "개인정보 수집 및 이용에 동의하세요",
  "register.message.alreadyUsedId": "이미 사용중인 ID 입니다.",
  "register.registered": "정상적으로 가입 되었습니다.",

};

export default messageKoKR;