import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  message,
  Typography,
  Dropdown,
  Space,
  Menu
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from './Api'
import ComponentEditModal from './ComponentEditModal';
import Util from '../../util/Util'

const { Text } = Typography

const ComponentList = (props) => {
  // console.log('props :>> ', props);
  const [components, setComponents] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState({});

  useEffect(() => {
    loadComponents()
  }, []);
  const loadComponents = async () => {
    let res = await Api.getComponents()
    // console.log('loadComponents.res :>> ', res.data);
    setComponents(res?.data?.data || [])
  }
  const showAddModal = (id) => {
    setSelectedComponent({});
    setVisibleEdit(true);
  }

  const refreshComponents = () => {
    loadComponents();
  };

  const componentColumns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    width: 80,
  },
  {
    title: "名称",
    dataIndex: 'name',
    key: 'name',
    width: 140,
  },
  {
    title: "Appid",
    dataIndex: 'appid',
    key: 'appid',
    width: 180,
  },
  // {
  //   title: "Secret",
  //   dataIndex: 'secret',
  //   key: 'secret',
  //   width: 100,
  //   ellipsis: true,
  // },
  {
    title: "Ticket",
    dataIndex: 'ticket',
    key: 'ticket',
    width: 100,
    ellipsis: true,
  },
  {
    title: "Token",
    dataIndex: 'token',
    key: 'token',
    width: 200,
    ellipsis: true,
    render: (text) => {
      return <CopyToClipboard text={text}
        onCopy={() => message.success('success')}>
        <a style={{ textDecoration: 'underline' }}>{text}</a>
      </CopyToClipboard>
    }
  },
  {
    title: "CallbackUrl",
    dataIndex: 'callbackUrl',
    key: 'callbackUrl',
    ellipsis: true,
    width: 100,
  },
  {
    title: "更新日期",
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    width: 180,
    render: (text) => {
      return <>{Util.getLocalTime(text)}</>
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    width: 120,
    align: 'center',
    render: (text, record) => {
      return (
        <Dropdown placement="bottom" overlay={actionMenu} trigger={['click']}>
          <Button type='primary' onClick={(e) => {
            e.preventDefault()
            setSelectedComponent(record)
          }}>
            <Space>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    }
  }];

  const handleMenuClick = ({ key }) => {
    // console.log('handleMenuClick.selectedComponent :>> ', selectedComponent);
    // console.log('key :>> ', key);
    if (key == '1') { //编辑
      setVisibleEdit(true)
    } else if (key == '2') { //删除
      if (!window.confirm(intl.get("common.confirm.delete", { name: selectedComponent.name }))) {
        return;
      }
      Api.deleteComponent(selectedComponent.id).then(res => {
        message.success('success')
        loadComponents()
      })
    } else if (key == '3') { //刷新token
      Api.refreshToken(selectedComponent.id).then(res => {
        message.success('success')
        loadComponents()
      })
    } else if (key == '4') {

    } else if (key == '5') {
    }
  };
  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        {intl.get("common.button.edit")}
      </Menu.Item>
      <Menu.Item key="2">
        {intl.get("common.button.delete")}
      </Menu.Item>
      <Menu.Item key="3">
        刷新Token
      </Menu.Item>
      <Menu.Item key="4">
        <CopyToClipboard text={'https://wop2-api.eryuan.co/wx-event/invite'}
          onCopy={() => message.success('success')}>
          <span>复制PC授权地址</span>
        </CopyToClipboard>
      </Menu.Item>
      <Menu.Item key="5">
        <CopyToClipboard text={'https://wop2-api.eryuan.co/wx-event/invite?mode=h5'}
          onCopy={() => message.success('success')}>
          <span>复制H5授权地址</span>
        </CopyToClipboard>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button type="primary" block size="default" shape="round" onClick={loadComponents}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        <div className="extra-op-box">
          <Button type="default" size="middle" onClick={() => showAddModal()}>{intl.get("common.button.add")}</Button>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table
          size={'small'}
          rowKey={'id'}
          dataSource={components}
          columns={componentColumns}
          pagination={false}
        />
      </div>

      <ComponentEditModal
        visibleEdit={visibleEdit}
        setVisibleEdit={setVisibleEdit}
        selectedComponent={selectedComponent}
        refreshComponents={refreshComponents}
      />
    </div>
  )
}

export default ComponentList;