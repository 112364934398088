import { adminConfig } from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class EventLogApi {
  static getEventLogs = (queryParam) => {
    let apiPath = `${API_BASE_URL}/wx-event-logs`;
    return axios.get(apiPath, { params: queryParam });
  };
  static getEventLog = (id) => {
    let apiPath = `${API_BASE_URL}/wx-event-logs/${id}`;
    return axios.get(apiPath);
  };
  static createEventLog = (param) => {
    const apiPath = `${API_BASE_URL}/wx-event-logs`;
    return axios.post(apiPath, param);
  };
  static updateEventLog = (param) => {
    let apiPath = `${API_BASE_URL}/wx-event-logs/${param.id}`;
    return axios.put(apiPath, param);
  };
  static deleteEventLog = (id) => {
    let apiPath = `${API_BASE_URL}/wx-event-logs/${id}`;
    return axios.delete(apiPath);
  }
  static deleteEventLogByIds = (ids) => {
    let apiPath = `${API_BASE_URL}/wx-event-logs`;
    return axios.delete(apiPath, {
      params: {
        ids: ids
      }
    });
  }
}