import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import PageLoadingRoute from "../../components/router/PageLoadingRoute";
import Sample from "../sample/Sample";
import WxComponent from "../wx_component/Component";
import Authorization from "../wx_authorization/Authorization";
import CodeTemplate from "../wx_code_template/CodeTemplate";
import AuditRelease from "../wx_audit_release/AuditRelease";
import EventLog from "../wx_event_log/EventLog";

const PageRouter = () => {
  return (
    <>
      <Switch>
        <PageLoadingRoute exact path="/" component={Dashboard} />
        <PageLoadingRoute exact path="/sample" component={Sample} />
        <PageLoadingRoute exact path="/component" component={WxComponent} />
        <PageLoadingRoute exact path="/authorization" component={Authorization} />
        <PageLoadingRoute exact path="/code-template" component={CodeTemplate} />
        <PageLoadingRoute exact path="/audit-release" component={AuditRelease} />
        <PageLoadingRoute exact path="/wx-event-log" component={EventLog} />

      </Switch>
    </>
  )
};

export default PageRouter;
