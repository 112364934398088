import React from "react";

import CommonApi from "../pages/common/CommonApi";
import Cookies from 'universal-cookie';
import moment from "moment-timezone";
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import { MemberContext } from "../auth/member.context";
import intl from "react-intl-universal";

const zone = moment.tz.guess();
const cookies = new Cookies();

export default class Util {

  static humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  static isAdmin = () => {
    return MemberContext.memberInformation.userType === "admin";
  };

  static currencyFormat = (value, currency) => {
    let num = value;
    let symbol = Util.getSymbol(currency);

    if (!num) {
      return symbol + "0";
    }

    if (typeof value === 'string' || value instanceof String) {
      num = parseFloat(value);
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return symbol + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return symbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };

  static numberFormat = (num) => {
    if (!num) {
      return "0";
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  static deleteAllCookies = () => {
    const allCookies = document.cookie.split(";");
    const domainName = "localhost";//Config.DOMAIN();
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const deleteCookie = `${name}=; domain=${domainName}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      console.log("===> deleteCookie:", deleteCookie);
      document.cookie = deleteCookie;
    }
  }

  static getMerchandiserId = () => {
    return cookies.get("merchandiserId") || "";
  }

  static getUser = () => {
    return cookies.get("user") || {};
  }

  static getLocale = () => {
    return cookies.get("locale") || ko_KR.locale
  }

  static unixTimeToStr(unixTime) {
    return moment.unix(unixTime).format('YYYY-MM-DD HH:mm:ss');
  }

  static getChinaLocalTime(dateTimeStr) {
    return moment.tz(dateTimeStr, 'Asia/Shanghai').format("YYYY-MM-DD HH:mm:ss") + "(GMT+8)";
  }

  static getLocalTime(dateTimeStr) {
    return moment.tz(dateTimeStr, zone).format("YYYY-MM-DD HH:mm:ss");
  }

  static getLocalTimeOrDate(dateTimeStr) {
    const localTime = moment.tz(dateTimeStr, zone);
    const dataDate = localTime.format("YYYY-MM-DD");
    const currentDate = moment().tz(zone).format("YYYY-MM-DD");
    if (dataDate != currentDate) {
      return (<span>{localTime.format("MM/DD")}<br />{localTime.format("HH:mm A")}</span>)
    } else {
      return moment.tz(dateTimeStr, zone).format("HH:mm A");
    }
  }

  static toFixed2(num) {
    return Math.round(num * 100) / 100
  }

  static encodeImagePath = (path) => {
    // filename에 "+" 기호가 있는 경우 제대로 나타나지 않는다.
    try {
      const url = new URL(path);
      if (path.endsWith("/")) {
        path = path.substring(0, path.length - 1);
      }

      const index = path.lastIndexOf("/");
      if (index < url.origin.length) {
        return path;
      }

      const fileName = path.substring(index + 1);
      const encodedPath = path.substring(0, index) + "/" + encodeURIComponent(fileName);
      return encodedPath;
    } catch (err) {
      return path;
    }
  }
  static getServiceDomainName = () => {
    const hostName = window.location.hostname;
    if (hostName === "localhost" || hostName === "127.0.0.1") {
      return hostName;
    }
    const hostTokens = hostName.split(".");
    if (hostTokens.length >= 2) {
      return hostTokens[hostTokens.length - 2] + "." + hostTokens[hostTokens.length - 1];
    } else {
      return "youma.kr";
    }
  }
}