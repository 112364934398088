import React, {useEffect, useState} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {adminConfig} from "../../config/admin.config";
import {AuthService} from "../../auth/auth.service";
import {
  CHANGE_MEMBER_CONTEXT_EVENT_TOPIC,
  EventBroadcaster,
  MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC
} from "../../event/event.broadcaster";

export default function AuthRoute(props) {
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();
  const [memberContextAvailable, setMemberContextAvailable] = useState(false);

  const { component: Component, needUpdate, path, exact = false } = props;
  const checkAuth = () => {
    // AuthService.checkAuth().then(() => {
    //   setAuthenticated(true);
    // }).catch((err) => {
    //   console.log(">>>>checkAuth error(goto login):", err);
    //   history.replace("/login");
    // })
    setAuthenticated(true);
    setMemberContextAvailable(true);
  };

  useEffect(() => {
    checkAuth();

    EventBroadcaster.on(MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC, (memberInformation) => {
      setMemberContextAvailable(true);
      EventBroadcaster.broadcast(
          CHANGE_MEMBER_CONTEXT_EVENT_TOPIC,
          memberInformation
      );
    });
  });

  // return (<div></div>)
  return <Route exact={exact} path={path} render={(props) => {
    if (authenticated && memberContextAvailable) {
      return (<Component needUpdate={needUpdate} {...props}/>)
    } else {
      return <div></div>
    }
  }}/>;
}
